
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n/index";

export default defineComponent({
  name: "customer-financial-statements-networth",
  components: {},
  setup() {
    const { t, te, n } = useI18n();

    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    return { nFormat };
  },
  data() {
    let isLoading = true;
    const route = useRoute();
    const store = useStore();
    const cstmr_id = route.params.id ?? null;
    return {
      cstmr_id,
    };
  },
  async mounted() {
    const pathArray = this.$route.path.split("/");
    let currPath = pathArray[pathArray.length - 1];

    if (this.cstmr_id) {
      if (currPath == "financial-planning") {
        setCurrentPageBreadcrumbs("Financial Planning", ["Customer"]);
      }

      if (currPath == "will-planning") {
        setCurrentPageBreadcrumbs("Will Planning", [
          "Customer",
          "Financial Planning",
        ]);
      }
      if (currPath == "fact-finding-sheet") {
        setCurrentPageBreadcrumbs("Fact Finding Sheet", [
          "Customer",
          "Financial Planning",
        ]);
      }
      if (currPath == "risk-planning") {
        setCurrentPageBreadcrumbs("Risk Planning", [
          "Customer",
          "Financial Planning",
        ]);
      }
    } else {
      if (currPath == "financial-planning") {
        setCurrentPageBreadcrumbs("Financial Planning", []);
      }

      if (currPath == "will-planning") {
        setCurrentPageBreadcrumbs("Will Planning", ["Financial Planning"]);
      }

      if (currPath == "fact-findings-sheet") {
        setCurrentPageBreadcrumbs("Fact Finding Sheet", ["Financial Planning"]);
      }
    }
  },
});
